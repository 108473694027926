.event-reminder{
  position: relative;
  padding: 20px;
  padding-right: 10px !important;
  border-radius: $outer-box-border-radius;
  background-color: $secondary-bg-color;
  border: 1px solid $border-color;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  &.in-request{
    margin-top: 20px;
  }

  @media screen and (max-width: 500px) {
    border-radius: $outer-box-mob-border-radius;
  }
}

.event-reminder__details{
  display: flex;
  flex-direction: row;
  align-items: center; 
}

.event-reminder__banner{
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: $inner-box-border-radius;
  margin-right: 10px;

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }
}

.add-to-calendar{
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}