.button {
  width: -webkit-fill-available;
  height: 45px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  border: 1px solid $white;
  border-radius: $inner-box-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $white;
  color: #282828;
  transition: opacity .35s ease, background-color .35s ease, border-color .35s ease;
  opacity: 1;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }

  @media (hover: hover) {
    &:hover {
      opacity: .85;
    }
  }

  &--invert {
    @extend .button;
    background-color: transparent;
    color: $button-bg-color;
    transition: background-color .35s ease, color .35s ease;

    @media (hover: hover) {
      &:hover {
        background-color: $button-bg-color;
        color: #282828;
        opacity: 1;
      }
    }
  }

  &--back {
    @extend .button;
    flex-direction: row-reverse;

    & > img,
    & > svg {
      margin-left: 0px;
      margin-right: 10px;
      padding-top: 1px;
      padding-bottom: 0;
    }
  }

  &.danger{
    border: 1px solid $red;
    background-color: $red;
    color: $white;
  }

  &.col-6 {
    grid-column: span 6;
  }
}