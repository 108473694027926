@import '../setup/style.scss';
@import '../components/sidebar';
@import '../components/loader';
@import '../components/event-reminder';
@import '../components/buttons';
@import '../components/inputs';
@import '../components/language-switcher';
@import '../components/request-a-table';
@import '../components/legal-content';

.data-box{
  padding: 25px;
  border: 1px solid $border-color;
  background-color: $secondary-bg-color;
  border-radius: 20px;
  margin-bottom: 10px;
  position: relative;

  &.center{
    padding: 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.status{
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

.reservation__shortcode{
  font-weight: 400;
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  color: $primary-text-color;
}

.reservation__details{
  max-width: 500px;
  width: 100%;
}

.qrcode-wrapper{
  padding: 25px;
  border-radius: 10px;
  background-color: $white;

  & > svg{
    width: 100%;
    height: 100%;
  }
}

.event-details{
  padding-top: 30px;
  padding-bottom: 30px;
}

.event-details__single{
  grid-column: span 6;

  &.big{
    grid-column: span 12;
  }
}

.event-details__single-name{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #EEEEEE;
  margin-bottom: 5px;
}

.prompt-wrapper{
  position: fixed;
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(50px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  transition: opacity .35s ease;
  opacity: 0;
  pointer-events: none;

  &.opened{
    opacity: 1;
    pointer-events: all;
  }

  & > h2{
    text-align: center;
  }
}

.prompt-buttons{
  width: 100%;
  max-width: 500px;
}

.reservation__info{
  text-align: center;
  margin: 0;
  padding: 0;
  
  &.space {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.reservation__h6{
  color: $secondary-text-color;
}

.event-details__cancelled{
  background-color: $dark-red;
  color: $white;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.disable-on-waiting-confirmation{
  opacity: 1;
  transition: opacity .35s ease;
}

.waiting-confirmation .disable-on-waiting-confirmation{
  opacity: .3;
  pointer-events: none;
}

.event-details__confirmations-data{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.event-details__confirmations-status{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-details__confirmations-text,
.event-details__confirmations-number{
  color: $white;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}