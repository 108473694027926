.input-wrapper {
  display: flex;
  flex-direction: column;

  &--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.input__label {
  padding: 10px 0;
}

input,
textarea {
  height: 45px;
  width: -webkit-fill-available;
  padding-left: 15px;
  font-family: $primary-font;
  color: $primary-text-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: $inner-box-border-radius;
  background-color: transparent;
  outline: none;
  margin-bottom: 10px;

  @media screen and (max-width: 500px)  {
    border-radius: $inner-box-mob-border-radius;
  }

  &::placeholder {
    font-weight: 400;
    color: $secondary-text-color;
  }
}

textarea {
  padding-top: 10px;
  resize: none;
  min-height: 128px;
  line-height: 20px;
}

/* remove input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* remove input type number */
input[type=number] {
  -moz-appearance: textfield;
}

.quantity{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.quantity__value{
  margin: 0 20px;
  color: $white;
}

.quantity__minus,
.quantity__plus{
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  position: relative;
  cursor: pointer;

  &.disabled{
    pointer-events: none;
    opacity: .4;
  }

  &::after{
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ACACAC;
  }
}

.quantity__plus::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ACACAC;
}

.inputs-disabled input,
.inputs-disabled textarea,
.inputs-disabled .quantity{
  pointer-events: none;
  opacity: .5;
  cursor: not-allowed;
}