.request-a-table__grid{
  width: 100%;
  max-width: 500px;
}

.request-a-table{
  padding-top: 40px;
  grid-column: 1 / span 12;

  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
}

.request__inputs{
  padding-top: 20px;
}

.request__status{
  padding: 20px;
  border-radius: $outer-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid $harmonies;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    border-radius: $outer-box-mob-border-radius;
    padding: 10px;
    margin-top: 10px;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }
}

.request__buttons{
  padding-top: 30px;
}

.request__edit-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  max-width: max-content;

  & > .label--gray{
    margin-left: 10px;
  }
}