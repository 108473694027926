.legal-content{
  padding-top: 40px;
  grid-column: 1 / span 12;

  @media screen and (min-width: 801px) {
    grid-column: 1 / span 12;
  }
  @media screen and (min-width: 1281px) {
    grid-column: 2 / span 10;
  }
}

.legal-content__h1{
  padding-top: 10px;
  padding-bottom: 20px;

  @media screen and (max-width: 1280px) {
    padding-bottom: 10px;
    padding-top: 0;
  }
}

.legal-content__h2{
  padding-top: 15px;
  padding-bottom: 15px;
}

.legal-content p:not(:last-of-type){
  padding-bottom: 15px;
}

.legal-content p > span{
  color: $white;
  font-weight: 600;
}

.legal-content ul {

  & > li{
    @extend p;
    padding-bottom: 5px !important;
  }
}